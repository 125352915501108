import React from "react"
import Layout from "../components/common/layout"
import notFoundStyles from "../styles/pages/not-found.module.scss"

const PageNotFound = ({ location }) => (
  <Layout
    pageMeta={{
      title: "404 pagina non trovata",
      keywords: [""],
      description: "404 pagina non trovata",
      link: "/404",
    }}
    location={location}
  >
    <p className={notFoundStyles.notFound}>Errore 404 - pagina non trovata.</p>
    <p className={notFoundStyles.text}>
      La pagina che hai cercato è stata rimossa o spostata.
    </p>
  </Layout>
)

export default PageNotFound
